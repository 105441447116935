<template>
  <div class="card card-custom">
    <b-overlay :show="showOverlay">
      <div class="card-body p-0">
        <div
          class="wizard wizard-3"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <WizardStepper
            :steps="[
              { id: 1, title: this.$t('CHECKOUT.INFO') },
              { id: 2, title: this.$t('CHECKOUT.DISCOUNT') },
              { id: 3, title: this.$t('CHECKOUT.SUMMARY') },
            ]"
            :current-step="step"
            :available-steps="availableSteps"
            @on-change-step="onChangeStep"
          />

          <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-7">
              <form class="kt-form" id="kt_form">
                <template v-if="step === 1">
                  <div
                    class="kt-wizard-v3__content"
                    data-ktwizard-type="step-content"
                    data-ktwizard-state="current"
                  >
                    <div class="kt-form__section kt-form__section--first">
                      <div class="kt-wizard-v3__form">
                        <checkout-card
                          :order="dto"
                          :ownershipMap="ownershipMap"
                          :step="step"
                          :currencies="currencies"
                          :loading="loading"
                          @onCurrencyChange="onCurrencyChange"
                        ></checkout-card>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="step === 2">
                  <div
                    class="kt-wizard-v3__content"
                    data-ktwizard-type="step-content"
                    data-ktwizard-state="current"
                  >
                    <div class="kt-form__section kt-form__section--first">
                      <div class="kt-wizard-v3__form">
                        <checkout-card
                          :step="step"
                          @onPromocodeChange="onPromocodeChange"
                        ></checkout-card>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="step === 3">
                  <div
                    class="kt-wizard-v3__content"
                    style="padding: 10px"
                    data-ktwizard-type="step-content"
                    data-ktwizard-state="current"
                  >
                    <div class="kt-form__section kt-form__section--first">
                      <div class="kt-wizard-v3__form">
                        <div
                          class="alert alert-light alert-elevate"
                          role="alert"
                        >
                          <div class="alert-text">
                            <p>
                              {{ $t("CHECKOUT.ALERT") }}
                            </p>
                          </div>
                        </div>
                        <checkout-card
                          :step="step"
                          :order="dto"
                        ></checkout-card>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="d-flex justify-content-between border-top pt-10">
                  <button
                    @click="onCancel"
                    type="button"
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  >
                    {{ $t("COMMON.CANCEL") }}
                  </button>

                  <button
                    type="button"
                    v-if="step === 3"
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                    @click="payment"
                    :disabled="loading"
                  >
                    {{ $t("CHECKOUT.DONE") }}
                  </button>
                  <button
                    type="button"
                    v-else
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                    @click="goToNextStep"
                    :disabled="loading"
                  >
                    {{ $t("COMMON.NEXT_STEP") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <debug></debug>
  </div>
</template>

<style lang="scss"></style>

<script>
import Application from "../../application";
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import WizardStepper from "@/view/content/WizardStepper";
import { GroupTypeOptions } from "@/common/config";
//import DivHorizontal from "@/view/content/div-horizontal";
import { API_REQUEST } from "../../core/services/store/api.module";
import CheckoutCard from "../component/checkout/CheckoutCard";
//import PaySystems from "../component/checkout/PaySystems";
import i18nService from "../../core/services/i18n.service";
import { mapState } from "vuex";

export default Vue.extend({
  name: "Checkout",
  components: {
    WizardStepper,
    CheckoutCard,
    //DivHorizontal,
    // PaySystems
  },
  mixins: [SuggestionProcessorMixin],

  data() {
    return {
      requests: {
        checkoutRequest: {
          resource: "platform/api/order/" + this.$route.params.id,
          requestType: "GET",
          requestParams: {
            enrich: true,
            withProducts: true,
          },
        },
        currenciesRequest: {
          resource: "/platform/api/order/available-currencies",
          requestType: "GET",
          requestParams: {},
        },
        getOwnership: {
          resource:
            "/platform/api/dictionary/com.gracelogic.platform.market.model.OwnershipType",
          requestType: "GET",
          requestParams: {},
        },
        save: {
          resource: "/platform/api/order/save",
          requestType: "POST",
          requestParams: {},
        },
        execute: {
          resource: "/platform/api/order/execute",
          requestType: "POST",
          requestParams: {},
        },
      },

      dto: {
        products: [],
      },
      contractor: {},
      currencies: [],
      ownershipMap: {},
      paySystem: Application.constants.PAYMENT_SYSTEMS.YANDEX_KASSA,
      profile: {},

      showOverlay: true,

      step: 1,
      loading: false,
      constants: Application.constants,
    };
  },
  async created() {
    this.loadOwnership();
    this.loadContractor();
    this.loadProfile();

    this.$store
      .dispatch(API_REQUEST, this.requests.currenciesRequest)
      .then((response) => {
        this.currencies = response;
      })
      .catch(this.onError);

    await this.loadOrder();
    if (
      !(
        this.dto.orderStateId === this.constants.ORDER_STATES.DRAFT ||
        this.dto.orderStateId === this.constants.ORDER_STATES.PENDING
      )
    ) {
      this.$bvToast.toast(this.$t("CHECKOUT.ORDER_HAS_INVALID_STATE"), {
        title: this.$t("COMMON.ERROR"),
        variant: "danger",
        autoHideDelay: 5000,
      });
      this.$router.replace({ path: "/monitoring" });
    } else if (this.dto.orderStateId === this.constants.ORDER_STATES.PENDING) {
      this.onChangeStep(3);
    }

    this.showOverlay = false;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("CHECKOUT.CREATE_CHECKOUT") },
    ]);
  },

  methods: {
    async onCurrencyChange(currencyId) {
      this.loading = true;
      this.dto.targetCurrencyId = currencyId;
      const request = this.requests.save;
      request.dto = this.dto;

      await this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.loadOrder();
        })
        .catch(this.onError);
      this.loading = false;
    },
    onPromocodeChange(promocode) {
      this.dto.discountSecretCode = promocode;
    },
    onPaySystemChange(paySys) {
      this.paySystem = paySys;
    },
    loadOwnership: function () {
      this.$store
        .dispatch(API_REQUEST, this.requests.getOwnership)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.ownershipMap[response[i].id] = response[i];
          }
        })
        .catch(this.onError);
    },
    loadProfile: function () {
      this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/profile/",
          requestType: "GET",
        })
        .then((response) => {
          this.profile = response;
        })
        .catch(this.onError);
    },

    loadContractor: function () {
      let request = {
        resource: "api/contractor/by-order",
        requestType: "GET",
        requestParams: {
          locale: i18nService.getActiveLanguage(),
          orderId: this.$route.params.id,
        },
      };

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.contractor = response;
        })
        .catch(this.onError);
    },

    async loadOrder() {
      await this.$store
        .dispatch(API_REQUEST, this.requests.checkoutRequest)
        .then((response) => {
          this.dto = response;
          let proList = "";
          for (let i = 0; i < response.products.length; i++) {
            proList += response.products[i].nameLocalized;
            if (i + 1 < response.products.length) {
              proList += ", ";
            }
          }
          this.dto.productsList = proList;
        })
        .catch(this.onError);
    },

    async applyDiscount() {
      this.loading = true;

      let applyResult = false;
      const request = this.requests.save;
      request.dto = this.dto;

      await this.$store
        .dispatch(API_REQUEST, request)
        .then(async (response) => {
          await this.loadOrder();
          applyResult = true;
        })
        .catch((response) => {
          this.onError(response);
          applyResult = false;
        });
      this.loading = false;
      return applyResult;
    },

    onCancel() {
      this.$router.go(-1);
    },

    onChangeStep(step) {
      this.step = step;
      if (this.step === 2) {
        this.dto.discountSecretCode = "";
      }
    },

    async goToNextStep() {
      if (this.step === 1) {
        this.dto.discountSecretCode = "";
      } else if (this.step === 2) {
        if (this.dto.discountSecretCode === "") {
          this.dto.discountId = null;
        }
        if (!(await this.applyDiscount())) {
          return;
        }
      }
      this.step = this.step + 1;
    },

    onError(response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    payment() {
      this.loading = true;

      switch (this.contractor.paymentSystemId) {
        case this.constants.PAYMENT_SYSTEMS.YANDEX_KASSA:
          this.payWithYandex();
          break;
        case this.constants.PAYMENT_SYSTEMS.ALFA_BANK:
          this.payWithAlfaBank();
          break;
        default:
          this.$bvToast.toast("Unknown payment system", {
            title: this.$t("COMMON.ERROR"),
            variant: "error",
            autoHideDelay: 5000,
          });
      }

      this.loading = false;
    },
    successfullyBuy() {
      let that = this;
      setTimeout(() => {
        that.$router.go(-1);
      }, 4000);

      this.$bvToast.toast(this.$t("CHECKOUT.SUCCESSFULLY_PURCHASED"), {
        title: this.$t("COMMON.SUCCESS"),
        variant: "success",
        autoHideDelay: 5000,
      });
    },
    payWithAlfaBank: async function () {
      this.showOverlay = true;
      const request = this.requests.execute;
      request.dto = {
        orderId: this.dto.id,
        paymentSystemId: this.constants.PAYMENT_SYSTEMS.ALFA_BANK,
        params: {
          //"customer_email":
          //"page_view":
          language: i18nService.getActiveLanguage(), // language in ISO 639-1
          redirect_url: window.location.origin + "/payment-state/success",
          fail_redirect_url: window.location.origin + "/payment-state/error",
        },
      };

      await this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          if (response.processed) {
            this.showOverlay = false;
            this.$router.push({
              name: "payment-state",
              params: { state: "success" },
            });
          } else if (response.params.confirmation_url != null) {
            window.open(response.params.confirmation_url, "_self");
          }
        })
        .catch(this.onError);
    },

    payWithYandex: async function () {
      if (!this.profile.email || this.profile.email === "") {
        this.$bvToast.toast(this.$t("CHECKOUT.PLEASE_ENTER_YOUR_EMAIL"), {
          title: this.$t("COMMON.ERROR"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        return;
      }

      this.showOverlay = true;
      const request = this.requests.execute;
      request.dto = {
        orderId: this.dto.id,
        paymentSystemId: this.constants.PAYMENT_SYSTEMS.YANDEX_KASSA,
        params: {
          customer_email: this.profile.email,
          customer_full_name: this.user.formattedUserName,
        },
      };
      await this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          if (response.processed) {
            this.showOverlay = false;
            this.$router.push({
              name: "payment-state",
              params: { state: "success" },
            });
          } else if (response.params.confirmation_url != null) {
            window.open(response.params.confirmation_url, "_self");
          }
        })
        .catch(this.onError);
    },
    payWithPayPal() {
      const request = this.requests.execute;
      request.dto = {
        orderId: this.dto.id,
        paymentSystemId: this.constants.PAYMENT_SYSTEMS.PAYPAL,
        params: {
          action: "create",
          use_billing_agreement: true,
        },
      };

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          if (response.params.confirmation_url != null) {
            window.open(response.params.confirmation_url);
          }
        })
        .catch(this.onError);
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    availableSteps() {
      let res = [];
      if (this.dto.orderStateId === this.constants.ORDER_STATES.PENDING) {
        res.push(3);
      } else {
        res.push(1);
        if (this.step >= 2) {
          res.push(2);
        }

        if (this.step === 3) {
          res.push(3);
        }
      }
      return res;
    },
  },
});
</script>
