<template>
  <b-card bg-variant="light">
    <div v-if="step === 1">
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.CREATION_DATE')">
        <b-form-datepicker
          :value="order.created"
          :locale="getLang()"
          value-as-date
          disabled
          :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }"
          :label-no-date-selected="$t('COMMON.NO_DATE')"
        ></b-form-datepicker>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.OWNERSHIP_TYPE')">
        <b-form-input
          id="input-ownership"
          :value="ownershipFormatter(order.ownershipTypeId)"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.PRODUCTS')">
        <b-form-input
          id="input-product"
          disabled
          :value="order.productsList"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.PRICE')">
        <b-input-group>
          <template v-slot:prepend>
            <b-input-group-text style="flex: 0 0 30%">{{
              order.targetCurrencyName
            }}</b-input-group-text>
          </template>
          <b-form-input
            v-model="order.amountAsFractional"
            disabled
          ></b-form-input>

          <template v-slot:append>
            <b-dropdown
              :text="$t('CHECKOUT.CURRENCY')"
              variant="success"
              :disabled="loading"
            >
              <b-dropdown-item
                v-for="item in currencies"
                :key="item.id"
                v-show="item.id !== order.targetCurrencyId"
                @click="$emit('onCurrencyChange', item.id)"
                >{{ item.name }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-input-group>
      </b-form-group>
    </div>
    <div v-else-if="step === 2">
      <p>
        {{ $t("CHECKOUT.PROMO_DESCRIPTION") }}
      </p>
      <b-form-input
        :placeholder="$t('CHECKOUT.PROMOCODE')"
        v-model="promocode"
        @update="$emit('onPromocodeChange', promocode)"
      ></b-form-input>
    </div>
    <div v-else-if="step === 3">
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.CREATION_DATE')">
        <b-form-datepicker
          :value="order.created"
          :locale="getLang()"
          value-as-date
          disabled
          :label-no-date-selected="$t('COMMON.NO_DATE')"
        ></b-form-datepicker>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.OWNERSHIP_TYPE')">
        <b-form-input
          id="input-ownership"
          :value="ownershipFormatter(order.ownershipTypeId)"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.PRODUCTS')">
        <b-form-input
          id="input-product"
          disabled
          :value="order.productsList"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.PRICE')">
        <b-input-group>
          <template v-slot:prepend>
            <b-input-group-text style="flex: 0 0 30%">{{
              order.targetCurrencyName
            }}</b-input-group-text>
          </template>
          <b-form-input
            :value="order.amountAsFractional"
            disabled
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.PROMOCODE')">
        <b-form-input
          id="input-promocode"
          disabled
          :value="order.discountName"
        ></b-form-input>
      </b-form-group>
      <div-horizontal></div-horizontal>
      <b-form-group label-cols-lg="2" :label="$t('CHECKOUT.TOTAL_AMOUNT')">
        <b-input-group>
          <template v-slot:prepend>
            <b-input-group-text style="flex: 0 0 30%">{{
              order.targetCurrencyName
            }}</b-input-group-text>
          </template>
          <b-form-input
            :value="order.totalAmountAsFractional"
            disabled
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
import DivHorizontal from "@/view/content/div-horizontal";
import { API_REQUEST } from "../../../core/services/store/api.module";
export default {
  name: "CheckoutCard",
  components: {
    DivHorizontal,
  },
  props: {
    order: {},
    ownershipMap: {},
    resultOrder: {},
    step: {},
    currencies: {},
    loading: {
      default: false,
    },
  },
  data() {
    return {
      promocode: "",
      ownerMap: {},
    };
  },
  created() {
    this.ownerMap = this.ownershipMap;
  },
  methods: {
    getLang() {
      return localStorage.getItem("language") || "en";
    },
    ownershipFormatter(value) {
      if (value !== null && this.ownerMap[value] !== undefined)
        return this.ownerMap[value].nameLocalized;
      return "";
    },
  },
  computed: {},
};
</script>

<style scoped></style>
