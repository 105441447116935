<template>
  <div class="container--">
    <hr />
    <div v-if="title" class="text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "div-horizontal",

  props: ["title"],
};
</script>

<style scoped>
.container-- {
  position: relative;
}
.text {
  text-align: center;
  width: 100px;
  position: absolute;
  right: calc(50% - 50px);
  top: -9px;
  background-color: #fff;
}
</style>
