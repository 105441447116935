<template>
  <div class="wizard-nav border-bottom">
    <div class="wizard-steps p-8 p-lg-10">
      <div
        class="wizard-step"
        data-wizard-type="step"
        v-for="(step, index) in steps"
        :key="step.id"
        :data-wizard-state="step.id === currentStep ? 'current' : undefined"
        @click="onChangeStep(step)"
        :class="{
          'no-available': step.id !== 1 && !_.includes(availableSteps, step.id),
        }"
      >
        <div class="wizard-label">
          <h3 class="wizard-title">
            <span>{{ step.id }}</span> {{ step.title }}
          </h3>
          <div class="wizard-bar"></div>
        </div>
        <i
          v-if="index !== steps.length - 1"
          class="wizard-arrow flaticon2-next"
        ></i>
      </div>
    </div>
  </div>

  <!--<div class="kt-grid__item">
    &lt;!&ndash;begin: Form Wizard Nav &ndash;&gt;
    <div class="kt-wizard-v3__nav">
      <div class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items&#45;&#45;clickable">
        &lt;!&ndash;doc: Replace A tag with SPAN tag to disable the step link click &ndash;&gt;

        <div
          class="kt-wizard-v3__nav-item"
          v-for="step in steps"
          :key="step.id"
          :data-ktwizard-state="step.id === currentStep ? 'current' : undefined"
          @click="onChangeStep(step)"
          :class="{
            'no-available':
              step.id !== 1 && !_.includes(availableSteps, step.id)
          }"
        >
          <div class="kt-wizard-v3__nav-body">
            <div class="kt-wizard-v3__nav-label">
              <span>{{ step.id }}</span> {{ step.title }}
            </div>
            <div class="kt-wizard-v3__nav-bar"></div>
          </div>
        </div>
      </div>
    </div>
    &lt;!&ndash;end: Form Wizard Nav &ndash;&gt;
  </div>-->
</template>

<script>
import _ from "lodash";

export default {
  name: "WizardStepper",

  props: {
    // [ {id: 1, title: 'Enter'} ]
    steps: Array,
    currentStep: Number,

    // list of id's
    availableSteps: Array,
  },

  methods: {
    onChangeStep(step) {
      _.includes(this.availableSteps, step.id) &&
        this.$emit("on-change-step", step.id);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<style scoped>
.no-available {
  cursor: not-allowed !important;
}
</style>
